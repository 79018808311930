import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { ApiService } from "../../common/http.service";

@Injectable({
  providedIn: 'root',
})
export class IComLibsServicesProjectAdminUserService {
  constructor(private apiService: ApiService) { }

  getDetailHistory(id: any) {
    const url = `${environment.apiGatewayUrl}${environment.suffixAdmin}v2.0/activity_logs/${id}`;
    return this.apiService.get(url, {id: id}) as Observable<any>;
  }

  getUserHistoryList(data: any): Observable<any> {
    const url = `${environment.apiGatewayUrl}${environment.suffixAdmin}v2.0/activity_logs`;
    return this.apiService.get(url, data) as Observable<any>;
  }

  getActivityFeatures(): Observable<any> {
    const url = `${environment.apiGatewayUrl}${environment.suffixAdmin}v2.0/activity_features`;
    return this.apiService.get(url) as Observable<any>;
  }

  getActivityActions(): Observable<any> {
    const url = `${environment.apiGatewayUrl}${environment.suffixAdmin}/v2.0/activity_actions`;
    return this.apiService.get(url) as Observable<any>;
  }
}
