import { filter } from 'lodash';

import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { environment } from "../../../environments/environment";
import { ApiService } from "../../common/http.service";

@Injectable({
  providedIn: 'root',
})

export class IComLibsServicesProjectFlashSaleService {
  constructor(private apiService: ApiService) {
  }

  create(data: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/flashsale/general`, data) as Observable<any>;
  }

  edit(data: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/flashsale/general`, data) as Observable<any>;
  }

  delete(id: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/flashsale/delete`, { _id: id }) as Observable<any>;
  }

  detail(id: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/flashsale/detail`, { _id: id }) as Observable<any>;
  }

  voucherList(filter: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/flashsale/voucher/list`, filter) as Observable<any>;
  }

  list(filter: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/flashsale/list`, filter) as Observable<any>;
  }

  listGroup(filter: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/flashsale/group/list`, filter) as Observable<any>;
  }


  deleteGroup(filter: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/flashsale/group/delete`, filter) as Observable<any>;
  }

  addGroup(filter: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/flashsale/group/general`, filter) as Observable<any>;
  }

  editGroup(filter: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/flashsale/group/general`, filter) as Observable<any>;
  }

  sortField(id: number, order: number): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/v1.0/sections/${id}/sort`, { new_order: order }) as Observable<any>;
  }

  transactionList(filter: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/flashsale/voucher/transaction/list`, filter) as Observable<any>;
  }

  uploadVoucher(filter: any) {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/flashsale/voucher/import`, filter, {
      'Content-Type': 'clear'
    }) as Observable<any>;
  }
  downloadFileVoucher() {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/flashsale/voucher/examfile`) as Observable<any>;
  }

  changeStatusActive(filter: any){
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/flashsale/active`, filter) as Observable<any>;
  }

  changeStatusInactive(filter: any){
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/flashsale/inactive`, filter) as Observable<any>;
  }
}

