import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgModule, inject } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app.routing.module";
import { AuthGuardService, ErrorHandlerInterceptor, HeaderInterceptor } from "@icom/services/authenticate";
import { IComMessageService, IconRegisterService } from "@icom/services/common";
import { NzModalService } from "ng-zorro-antd/modal";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormlyModule } from '@ngx-formly/core';
import { ArrayTypeComponent, CollapseWrapperComponent, DropdownTableTypeComponent, EmailValidator, FormlyFieldColorPickerComponent, FormlyFieldCustomInputComponent, FormlyFieldDrawerLinkComponent, FormlyFieldNzInputNumber, FormlyFieldRadio, FormlyFieldSelect, FormlyFieldTextareaCustom, FormlyWrapperNzSelectTagsComponent, GridWrapperComponent, IcomLibsCoreComponentFormlyFieldSearchInput, IcomLibsCoreComponentsFormlyDatepicker, IcomLibsCoreComponentsFormlyPanelWrapperComponent, IcomLibsCoreComponentsFormlyQuillComponent, IcomLibsCoreComponentsFormlyRangepicker, IcomLibsCoreComponentsFormlyUploadComponent, MaxUploadFileSizerValidator, MaxValidatorMessageValidator, MultiCheckboxTypeComponent, NumberIntMessage, NumberIntMessageValidator, PasswordToggleWrapperComponent, PhoneNumberValidator, TypeUploadFileSizerMessage, TypeUploadFileSizerValidator, emailValidationMessage, fieldMatchValidator, matchingPasswordsValidator, maxUploadFileSize, maxValidatorMessage, minValidatorMessage, noWhitespaceMessage, noWhitespaceValidator, phoneNumberValidationMessage, uniqueSelection } from '@icom/components/formly';
import { NZ_I18N, en_US } from "ng-zorro-antd/i18n";
import { NZ_ICONS } from "ng-zorro-antd/icon";
import { ArrowLeftOutline, LogoutOutline, UserOutline, EditFill } from '@ant-design/icons-angular/icons';
import { NgxsModule } from "@ngxs/store";
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from "libs/core/environments/environment";

const antDesignIcons = [ArrowLeftOutline, LogoutOutline, UserOutline, EditFill]

@NgModule({
	imports: [
		BrowserModule,
		HttpClientModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		NgxsModule.forRoot([]),
		ServiceWorkerModule.register('/ngsw-worker.js', { enabled: !environment.production }),
		FormlyModule.forRoot({
			types: [
				{ name: 'date', component: IcomLibsCoreComponentsFormlyDatepicker },
				{ name: 'icom-range-picker', component: IcomLibsCoreComponentsFormlyRangepicker },
				{ name: 'upload', component: IcomLibsCoreComponentsFormlyUploadComponent },
				{ name: 'quill-editor', component: IcomLibsCoreComponentsFormlyQuillComponent },
				{ name: 'array', component: ArrayTypeComponent },
				{
					name: 'select-custom',
					component: FormlyFieldSelect,
					wrappers: ['form-field'],
				},
				{ name: 'multi-checkbox', component: MultiCheckboxTypeComponent },
				{ name: 'search-input', component: IcomLibsCoreComponentFormlyFieldSearchInput },
				{
					name: 'color-picker',
					component: FormlyFieldColorPickerComponent,
					wrappers: ['form-field'],
				},
				{ name: 'dropdown-table', component: DropdownTableTypeComponent },
				{ name: 'nz-input-number', component: FormlyFieldNzInputNumber },
				{ name: 'tags', component: FormlyWrapperNzSelectTagsComponent},
				{ name: 'input-custom', component: FormlyFieldCustomInputComponent,	wrappers: ['form-field'], },
				{ name: 'radio-custom', component: FormlyFieldRadio,	wrappers: ['form-field'], },
				{ name: 'textarea-custom', component: FormlyFieldTextareaCustom,	wrappers: ['form-field'], },
				{ name: 'password-custom', component: PasswordToggleWrapperComponent },
				{
          name: 'drawer-link',
          component: FormlyFieldDrawerLinkComponent,
          wrappers: ['form-field'], // Hoặc wrapper tùy chỉnh nếu bạn cần
        },
			],
			validators: [
				{ name: 'email', validation: EmailValidator },
				{ name: 'phoneNumber', validation: PhoneNumberValidator },
				{ name: 'max-upload-file', validation: MaxUploadFileSizerValidator },
				{
					name: 'type-upload-file', validation: TypeUploadFileSizerValidator
				},
				{ name: 'max', validation: MaxValidatorMessageValidator },
				{ name: 'iniMin0', validation: NumberIntMessageValidator },
				{ name: 'fieldMatch', validation: fieldMatchValidator },
				{ name: 'noWhitespace', validation: noWhitespaceValidator },
				{ name: 'matchingPasswordsValidator', validation: matchingPasswordsValidator }
			],
			validationMessages: [
				{ name: 'required', message: 'Trường này là bắt buộc' },
				{ name: 'email', message: emailValidationMessage },
				{ name: 'phoneNumber', message: phoneNumberValidationMessage },
				{ name: 'max', message: maxValidatorMessage },
				{ name: 'min', message: minValidatorMessage },
				{ name: 'max-upload-file', message: maxUploadFileSize },
				{ name: 'type-upload-file', message: TypeUploadFileSizerMessage },
				{ name: 'iniMin0', message: NumberIntMessage },
				{ name: 'noWhitespaceMessage', message: noWhitespaceMessage },
				{ name: 'uniqueName', message: 'Tên này đã được sử dụng.' },
				{ name: 'startTimeInvalid', message: 'Thời gian kết thúc  không được nhỏ hơn thời gian bắt đầu' },
				{ name: 'startTimeInvalid5Minutes', message: 'Thời gian đến cần > Thời gian từ ít nhất là 5 phút.' },
				{ name: 'uniqueSelection', message: uniqueSelection}
			],
			wrappers: [
				{ name: 'group', component: IcomLibsCoreComponentsFormlyPanelWrapperComponent },
				{ name: 'collapse', component: CollapseWrapperComponent },
				{ name: 'grid', component: GridWrapperComponent }
			],
			extras: {
				resetFieldOnHide: false,
			},
		}),
		IcomLibsCoreComponentsFormlyRangepicker,
		IcomLibsCoreComponentsFormlyDatepicker,
		IcomLibsCoreComponentsFormlyQuillComponent,
	],
	declarations: [
		AppComponent,

	],
	providers:
		[
			AuthGuardService, IComMessageService, NzModalService,
			{ provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
			{
				provide: HTTP_INTERCEPTORS,
				useClass: ErrorHandlerInterceptor,
				multi: true,
			},
			{ provide: NZ_I18N, useValue: en_US },
			{ provide: NZ_ICONS, useValue: antDesignIcons },
		],

	bootstrap: [AppComponent],
})
export class AppModule {
	protected iconRegister = inject(IconRegisterService);
	constructor() {
		this.iconRegister.loadIcons(['job', 'gift', 'point'], 'assets/icons');
	}
}
