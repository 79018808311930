import { NgClass, NgIf } from '@angular/common';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { Component, OnInit } from '@angular/core';
import { FieldWrapper, FormlyModule } from '@ngx-formly/core';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyNgZorroAntdModule } from '@ngx-formly/ng-zorro-antd';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzFormModule } from 'ng-zorro-antd/form';

@Component({
  selector: 'formly-wrapper-collapse',
  template: `
    <nz-collapse *ngIf="props" nzAccordion [nzExpandIconPosition]="'right'">
      <nz-collapse-panel
        
          [nzHeader]="headerTemplate"
          [nzActive]="true"
          [nzDisabled]="props.readonly"
          [nzShowArrow]="!props.readonly">
          <ng-template #headerTemplate let-field>
            <label  class="text-sm font-semibold" [nzDisabled]="props.readonly" nz-checkbox [(ngModel)]="checked" (click)="handlerClick($event)" (nzCheckedChange)="handlerCheck($event)">
              {{ props.label }}
            </label>

            <i nz-icon nzType="caret-right" class="panel-toggle-icon"></i>
          </ng-template>
          <div *ngIf="hasContent()">
            <ng-container #fieldComponent></ng-container>
          </div>
      </nz-collapse-panel>
    </nz-collapse>
  `,
  standalone: true,
  imports: [NgIf, NzCollapseModule, NzCheckboxModule, FormsModule,
    NzCheckboxModule,
    NzDatePickerModule,
    ReactiveFormsModule,
    FormlyNgZorroAntdModule,
    NzFormModule,
    FormlyNgZorroAntdModule,
    FormlyModule,
    NgIf,
    NzFormModule,
    NgClass
  ]
})
export class CollapseWrapperComponent extends FieldWrapper implements OnInit {
  protected checked!: boolean;

  protected handlerClick(event: Event) {
    event.stopPropagation();
    event.preventDefault();
  }

  hasContent(): boolean {
    return this.field.fieldGroup && this.field.fieldGroup.some(field => !field.hide) || true;
  }

  protected handlerCheck(checked: boolean) {
    if (this.props.changeChecked) {
      this.props.changeChecked(checked, this.field);
      setTimeout(() => {
        this.formControl.markAsTouched(); // Đánh dấu là đã "chạm" vào để trigger validation
        this.options.detectChanges && this.options.detectChanges(this.field);
      }, 100)

    }
  }

  ngOnInit() {
    if (this.props.setChecked) {
      this.checked = this.props.setChecked(this.field);
    }
  }

}

