import { NgModule, importProvidersFrom } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService, ResolveLoginSuccessGuardService } from '@icom/services/authenticate'
import { NgxsModule } from '@ngxs/store';
export const appRoutes: Routes = [
	{
		path: '',
		canActivate: [AuthGuardService],
		loadChildren: () => import('@icom/layout').then(m => m.IcomLibsComponentsLayoutModule),
	},
  	{
		path: 'login',
		canActivate: [ResolveLoginSuccessGuardService],
		loadComponent: () => import('@icom/login').then(m => m.IcomLibsComponentsLoginComponent),
	},
	
];
@NgModule({
	imports: [
		RouterModule.forRoot(appRoutes, {
			initialNavigation: 'enabledBlocking',
			paramsInheritanceStrategy: 'always',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule { }
