import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, finalize, map } from 'rxjs';
import { IUserLogin } from '../../../interfaces/projects/iam/login.interface';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from '../../authenticate/local-storage.service';
import { ApiService } from '../../common/http.service';

@Injectable({
	providedIn: 'root',
})
export class IcomLibsProjectIamAccountService {
	constructor(private apiService: ApiService) { }

	add(data: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixIam}/v1/account/cms/user`, data) as Observable<any>;
}
	
	list(data: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixIam}/v1/account/users`, data) as Observable<any>;
  }
}
