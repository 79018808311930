// nz-input-number.type.ts
import { NgIf, NgFor, NgClass, JsonPipe, DecimalPipe } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FieldType, FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyNgZorroAntdModule } from '@ngx-formly/ng-zorro-antd';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';

@Component({
  selector: 'formly-field-nz-input-number',
  template: `
    <nz-form-item class="ant-form-item ant-row ant-form-item-required {{ to.className }}">
      <nz-form-label *ngIf="to.label" [nzRequired]="to.required">{{ to.label }}</nz-form-label>
      <nz-form-control class="ant-form-item-control">
      <nz-input-number-group>
        <nz-input-number *ngIf="!to.readonly; else readonlyTemplate"
          [ngClass]="{'!w-[120px]':  props.type === 'number'}"
          [formControl]="$any(formControl)"
          [formlyAttributes]="field"
          [nzMin]="1"
          [nzStep]="1"
          style="width: 100%"
          [nzPlaceHolder]="props.placeholder || 'Nhập số'"
          (keydown)="preventInput($event)">
        </nz-input-number>
        <span *ngIf="to.addonText && !to.readonly" class="input-addon">{{ to.addonText }}</span>
      </nz-input-number-group>
      <ng-template #readonlyTemplate>
        <span>{{ formControl.value | number : '1.0-2'   }}</span>
        <span class="input-addon ml-4">{{ to.addonText }}</span>
      </ng-template>
      <div class="ant-form-item-explain" [ngClass]="{'ant-form-item-explain-connected' : !showErr(field)}">
        <div class="ant-form-item-explain-error">
          <formly-validation-message [field]="field"></formly-validation-message>
        </div>
      </div>
    </nz-form-control>
    </nz-form-item>
  `,
  standalone: true,
  styles: [`
  .custom-input-container {
    display: flex;
    align-items: center;
  }
  .input-addon {
    margin-left: 8px;
  }
`],
  imports: [
    NzDatePickerModule,
    ReactiveFormsModule,
    FormlyNgZorroAntdModule,
    FormlyModule,
    NgIf,
    NgFor,
    NzFormModule,
    NzButtonModule,
    NzInputNumberModule,
    NgClass,
    JsonPipe,
    DecimalPipe
  ],
})
export class FormlyFieldNzInputNumber extends FieldType {

  showErr(field: FormlyFieldConfig) {
    return field.formControl && field.formControl.invalid && field.formControl.touched;
  }

  preventInput(event: KeyboardEvent) {
    // Cho phép các phím điều hướng và điều khiển
    const allowedKeys = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Backspace', 'Tab', 'Delete', 'End', 'Home'];
    if (event.key === 'Enter') {
      // Nếu phím nhấn là Enter, ngăn chặn sự kiện
      event.preventDefault();
      return;
    }

    if (allowedKeys.includes(event.key)) {
      return; // Cho phép các phím điều hướng và điều khiển
    }
  
    const char = event.key;
    const regex = /^[0-9]$/; // Chỉ cho phép số từ 0 đến 9
  
    if (!regex.test(char)) {
      event.preventDefault(); // Ngăn chặn nhập ký tự không phải số
    }
  }
}
