import { IEnvironment } from "./environment.model";

export const environment: IEnvironment = {
	production: false,
	module: "ep",
	title: "EPoint Administrator",
	favicon: "assets/imgs/epoint-logo.png",
	welcome: "Welcome to EPoint Administrator",
	banner: "assets/imgs/epoint-banner.png",
	workSpaceCode: '20984',
	apiGetFileStatic: "https://api.sandbox.mypoint.com.vn/",
	apiGatewayUrl: "https://api.sandbox.evnpoint.com/20984/",
	merchantUrl: 'http://222.252.26.153:9355/v1/',
	defaultDisplay: 4, 
	suffixCampaign: "gup2start/admin/rest/campaign-service/api/v2.0/",
	suffixCampaignV3: "gup2start/admin/rest/campaign-service-v3/api/v3.0/",
	suffixOrder: "gup2start/admin/rest/order/api/v1.0/",
	suffixDynamicHome: "gup2start/admin/rest/dynamic-home/api",
	suffixProduct: "gup2start/admin/rest/product/api/",
	suffixAdmin: "/gup2start/admin/rest/user/api/",
	suffixIam: "gup2start/rest/iam/",
};
