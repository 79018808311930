import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ModuleEpointGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(): boolean {
    if (environment?.module === 'ep') {
      return false;
    }
    return true;
  }
}